// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.demo.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  projectConfiguration: {
    name: "POLIPUSH_DEMO",
    API_URL: "https://polipush.demo.api.testctsolution.it/api"
  },
  keys: {
    TinyMCE: "boqg39ohpzcc8jme5jt2focybafrja1frizyfdnt53fa7i6w"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
