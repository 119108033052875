<div class="digital-signature-cntr">
    <img alt="homepage" src="assets/companies/AXPOITALIA/logo.png" height="55" />
    <h2 class="title">Benvenuto nella procedura<br>di firma digitale</h2>
    <p class="description">
      Assicurati di inserire correttamente i dati del firmatario.
    </p>


    <form [formGroup]="signatureForm" (ngSubmit)="onSubmit()">
      <mat-form-field appearance="fill">
        <mat-label>Nome</mat-label>
        <input matInput formControlName="nome" required>
      </mat-form-field>
      
      <mat-form-field appearance="fill">
        <mat-label>Cognome</mat-label>
        <input matInput formControlName="cognome" required>
      </mat-form-field>
      
      <mat-form-field appearance="fill">
        <mat-label>Numero di Telefono</mat-label>
        <input matInput formControlName="telefono" required pattern="[0-9]{10}">
      </mat-form-field>

<p class="m-t-0">Il firmatario riceverà un codice OTP sul numero indicato.</p>
      <button
                mat-raised-button
                color="primary"
                class="btn-block btn-lg m-t-10 m-b-10"
                type="submit"
                [disabled]="!signatureForm.valid"
                >
                Procedi alla firma
              </button>
    </form>
  </div>
  <div *ngIf="showFrame" id="iframe-container" (click)="disableIframe()"></div>
