import { Component } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-digital-signature',
  templateUrl: './digital-signature.component.html',
  styleUrls: ['./digital-signature.component.scss']
})
export class DigitalSignatureComponent {
  signatureForm: FormGroup;

  yousign: any;
  showFrame = false;
  iFrame = false;
  finish = false;

  constructor(private fb: FormBuilder, private route: Router) {
    this.signatureForm = this.fb.group({
      nome: ['', Validators.required],
      cognome: ['', Validators.required],
      telefono: ['', [Validators.required, Validators.pattern('[0-9]{10}')]],
    });

  }


  viewContract() {
    // Inserire link
    window.open('/', '_blank');
  }

  onSubmit() {
    // Cosa apre dopo
    if (!this.signatureForm.valid) return;
    this.showFrame = true;
    setTimeout(() => {
      this.yousign = new Yousign({
        signatureLink: "https://yousign.app/signatures/674da347-2984-49ce-ac51-d5c1c4e34bf8?s=088d53b0fdf2bf772a90343213b9299181f405b5cb56b8de8ed322ac2829574d91ec2a529c32b05ff97ad291d8c177e498a8aae490a5cda0fb6fd475b91fec35&r=2be3cd00aba2630f486306b8ce2e888a&source=email&lang=it&magic_link_id=69b7e208-b929-41e7-b4b3-4b6ac39f005f&domain_id=1d47ebd4e8&k=Z2tZ9KGg445xHKNtznUfj6uhyHWP0tMh&sandbox=true",
        iframeContainerId: 'iframe-container',
        isSandbox: true, //To set if you use the sandbox environment
      });
      this.iFrame = true;

      this.yousign.onSignatureDone((data: any) => {

        this.finish = true;

        setTimeout(() => {

          this.route.navigate(['/digital-signature/thank-you']);
          if (this.iFrame) {
            this.showFrame = false;
          }

        }, 15000);

      });

      window.addEventListener('message', function(e) {

        // Check if the origin of the message is really come from Yousign
        if(e.origin === 'https://yousign.app' && e.data.type === 'yousign'){
          
          if(e.data.event === 'declined'){
            setTimeout(() => {
              this.window.location.href = "/digital-signature/error-message";
            }, 15000);
          }

          if(e.data.event === 'error'){
            setTimeout(() => {
              this.window.location.href = "/digital-signature/error-message";
            }, 15000);
          }
        }
      });

    }, 100);

  }

  disableIframe() {

    if (this.finish)
      this.route.navigate(['/digital-signature/thank-you']);


    if (this.iFrame) {
      this.showFrame = false;
    }


  }
}
